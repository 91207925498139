<template>
  <article class="container-fluid">
    <h1 class="main-title">{{ $t("mainNav.downtimeReasons") }}</h1>

    <downtime-reasons />
  </article>
</template>

<script>
import DowntimeReasons from "../components/downtimereason/DowntimeReasons";

export default {
  name: "DowntimeReasonsMgmt",
  components: {
    DowntimeReasons,
  },
};
</script>

<style scoped></style>
